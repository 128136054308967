// Create a global methods before we apply Zone.js patches
const originalSetTimeout = window.setTimeout;
window.setTimeoutOutsideAngular = function setTimeoutOutsideAngular(...args: unknown[]): number {
  return originalSetTimeout.apply(this, args);
};

const originalClearTimeout = window.clearTimeout;
window.clearTimeoutOutsideAngular = function clearTimeoutOutsideAngular(...args: unknown[]): number {
  return originalClearTimeout.apply(this, args);
};

const originalElementAddEventListener = Element.prototype.addEventListener;
Element.prototype.addEventListenerOutsideAngular = function addEventListenerOutsideAngular(...args: unknown[]): void {
  originalElementAddEventListener.apply(this, args);
};

const originalWindowAddEventListener = window.addEventListener;
window.addEventListenerOutsideAngular = function addEventListenerOutsideAngular(...args: unknown[]): void {
  originalWindowAddEventListener.apply(this, args);
};

const ignoreProperties = ["scroll", "mousemove", "wheel", "resize", "dragenter", "dragover", "dragend", "drop", "paste", "input"];

// disable "on" properties, e.g. onclick
const targets = [window, Document, HTMLBodyElement, HTMLElement];
window.__Zone_ignore_on_properties = [];
for (const target of targets) {
  window.__Zone_ignore_on_properties.push({ target, ignoreProperties });
}

window.__Zone_disable_requestAnimationFrame = true;
window.__Zone_disable_canvas = true;

// disable addEventListener methods
window.__zone_symbol__UNPATCHED_EVENTS = ignoreProperties;
